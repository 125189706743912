import React from 'react';
import { Grid } from 'semantic-ui-react';
import Image from './Image';

const renderLongDescription = descriptions =>
  descriptions &&
  descriptions.map((description, index) => <p key={index}>{description}</p>);

const renderTech = technologies =>
  technologies &&
  technologies.map((tech, index) => (
    <li key={index}>
      <a>{tech}</a>
    </li>
  ));

const Modal = ({ isOpen, onModalClose, project }) =>
  project ? (
    <div className="modal">
      <div className={`p-overlay ${isOpen ? 'active' : ''}`}>
        <div className="portfolio-single">
          <div className="portfolio-field portfolio-title">
            <h2>
              {project.title}
              <div className="icon back" onClick={onModalClose}>
                <i className="fa fa-times fa-2x" aria-hidden="true" />
              </div>
            </h2>
          </div>

          <Grid stackable columns={2}>
            <Grid.Column className="modal-column-image">
              <div className="portfolio-field portfolio-image">
                <Image
                  alt={project.title}
                  filename={`${project.image}_main`}
                  size="600"
                />
              </div>
            </Grid.Column>

            <Grid.Column style={{ display: 'inline' }}>
              <div className="portfolio-field portfolio-detail">
                <div className="portfolio-detail--left">
                  <h3>Description</h3>
                  {renderLongDescription(project.long_description)}
                </div>
                <div className="portfolio-detail--right">
                  <h3>Github</h3>
                  <p>
                    <a
                      href={project.github}
                      alt={project.title}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      <i
                        className="fa fa-github fa-2x modal-icon"
                        aria-hidden="true"
                      />
                    </a>
                  </p>

                  <h3>Technology</h3>
                  <ul className="tags">{renderTech(project.technology)}</ul>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );

export default Modal;
